import { Word } from '../../../../types';

export const food: Word[] = [
  {
    id: 'food_1',
    french: 'restaurant',
    english: 'restaurant',
    usage: 'Allons dîner au restaurant ce soir.' // Let's have dinner at the restaurant tonight.
  },
  {
    id: 'food_2',
    french: 'menu',
    english: 'menu',
    usage: `Puis-je voir le menu, s'il vous plaît ?` // Can I see the menu, please?
  },
  {
    id: 'food_3',
    french: 'réservation',
    english: 'reservation',
    usage: `J'ai fait une réservation pour deux personnes.` // I made a reservation for two people.
  },
  {
    id: 'food_4',
    french: 'entrée',
    english: 'appetizer',
    usage: 'Comme entrée, je prendrai la salade.' // For the appetizer, I'll have the salad.
  },
  {
    id: 'food_5',
    french: 'plat principal',
    english: 'main course',
    usage: 'Quel est le plat principal du jour ?' // What is today's main course?
  },
  {
    id: 'food_6',
    french: 'dessert',
    english: 'dessert',
    usage: `Je n'ai plus de place pour le dessert.` // I don't have room for dessert.
  },
  {
    id: 'food_7',
    french: 'addition',
    english: 'bill',
    usage: `Pouvons-nous avoir l'addition, s'il vous plaît ?` // Can we have the bill, please?
  },
  {
    id: 'food_8',
    french: 'pourboire',
    english: 'tip',
    usage: `N'oubliez pas de laisser un pourboire.` // Don't forget to leave a tip.
  },
  {
    id: 'food_9',
    french: 'végétarien',
    english: 'vegetarian',
    usage: `Avez-vous des options végétariennes ?` // Do you have vegetarian options?
  },
  {
    id: 'food_10',
    french: 'boisson',
    english: 'drink',
    usage: 'Quelle boisson désirez-vous ?' // What drink would you like?
  }
];
