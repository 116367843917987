'use client';

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Chapter } from '../../types';
import Chip from '@mui/material/Chip';
import { Toolbar } from '@mui/material';
// import dayjs from 'dayjs';

// import { useSelection } from '@/hooks/use-selection';

function noop(): void {
  // do nothing
}

interface CourseTableProps {
//    courseData?: object;
   curriculum: Chapter[];
   level: String;
}

export function CourseTable({  curriculum = [],  level = "A1 Level"
  }: CourseTableProps): React.JSX.Element {

    const groupedChapters = curriculum.filter(x=> x.level == level)

  return (
    <Card>
      <Box sx={{ overflowX: 'auto' }}>

          <Toolbar>
            <Typography>
              {level}
            </Typography>
          </Toolbar>


        <Table sx={{ minWidth: '200px' }}>
          <TableHead>
                <TableRow>
                <TableCell>Chapter name</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Lessons</TableCell>
                <TableCell>Status</TableCell>
                </TableRow>
          </TableHead>
          <TableBody>
            {groupedChapters.map((chapter: Chapter ) => {
                return(
                  <TableRow hover key={chapter.chapterId}>
                      <TableCell>
                      <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                          <Typography variant="subtitle2">{chapter.chapterTitle}</Typography>
                      </Stack>
                      </TableCell>
                      <TableCell>{level}</TableCell>
                      <TableCell>{chapter.lessons.length}</TableCell>
                      <TableCell>
                          <Chip color={"success"} label={"new"} size="small" />
                      </TableCell>
                  </TableRow>
                )
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider/>
    </Card>
  );
}