// SignupPage.tsx

import React, { useState } from 'react';
import { UserManager } from '../components/UserManager';
import { Alert, Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '../components/Avatar';
import { styled } from '@mui/system';

const userManager = new UserManager();

const SignupPage: React.FC = () => {
  const navigate = useNavigate();

  const [avatarSeed, setAvatarSeed] = useState(Math.random().toString(36).substring(7));
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const StyledForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  });

  const handleRandomizeAvatar = () => {
    setAvatarSeed(Math.random().toString(36).substring(7));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email && name && password) {
      const existingUser = userManager.getUserByEmail(email);
      if (existingUser) {
        setError('User with this email already exists');
      } else {
        const newUser = userManager.signupUser(email, name, password, 0);
        console.log('New user created:', newUser);
        // Set notification in localStorage
        localStorage.setItem('notification', JSON.stringify({
          type: 'success',
          message: 'Signup successful! Welcome to our service.'
        }));
        // Redirect to home page
        navigate('/');
      }
    }
  }
  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 17, marginBottom: 7, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Sign Up
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <StyledForm onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
              <Avatar seed={avatarSeed} size={60} />
              <Button onClick={handleRandomizeAvatar} sx={{ ml: 2 }}>
                Randomize your Avatar
              </Button>
            </Box>

            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
            />
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth 
              size="large"
              sx={{ mt: 2 }}
            >
              Sign Up
            </Button>
          </StyledForm>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link to="/login">
              Already have an account? Log in
            </Link>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default SignupPage;
