import { useState, useEffect } from 'react';
import { UserWordProgress } from '../types';
import { calculateNextReviewDate } from '../utils/spaceRepetition';

export const useUserProgress = (userId: string) => {
  const [userProgress, setUserProgress] = useState<UserWordProgress[]>([]);

  useEffect(() => {
    // Load user progress from API or local storage
    // This is a placeholder implementation
    const loadedProgress = JSON.parse(localStorage.getItem(`userProgress_${userId}`) || '[]');
    setUserProgress(loadedProgress);
  }, [userId]);

  const saveProgress = (newProgress: UserWordProgress[]) => {
    // Save user progress to API or local storage
    // This is a placeholder implementation
    localStorage.setItem(`userProgress_${userId}`, JSON.stringify(newProgress));
    setUserProgress(newProgress);
  };

  const addWordProgress = (wordId: string, isCorrect: boolean) => {
    const existingProgress = userProgress.find((p) => p.wordId === wordId);
    if (existingProgress) {
      const updatedProgress = {
        ...existingProgress,
        correctCount: isCorrect ? existingProgress.correctCount + 1 : existingProgress.correctCount,
        incorrectCount: isCorrect ? existingProgress.incorrectCount : existingProgress.incorrectCount + 1,
        nextReviewDate: calculateNextReviewDate(isCorrect ? existingProgress.correctCount + 1 : 0),
      };
      saveProgress(userProgress.map((p) => (p.wordId === wordId ? updatedProgress : p)));
    } else {
      const newProgress: UserWordProgress = {
        wordId,
        correctCount: isCorrect ? 1 : 0,
        incorrectCount: isCorrect ? 0 : 1,
        nextReviewDate: calculateNextReviewDate(isCorrect ? 1 : 0),
      };
      saveProgress([...userProgress, newProgress]);
    }
  };

  const completeReview = (wordId: string) => {
    const updatedProgress = userProgress.map((p) =>
      p.wordId === wordId
        ? { ...p, nextReviewDate: calculateNextReviewDate(p.correctCount + 1) }
        : p
    );
    saveProgress(updatedProgress);
  };

  const scheduledReviews = userProgress.filter((p) => p.nextReviewDate <= new Date());

  return { addWordProgress, completeReview, scheduledReviews };
};
