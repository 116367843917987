// src/pages/Contact.tsx

import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

const Contact: React.FC = () => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Handle form submission logic here
        alert('Form submitted!');
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400, margin: '0 auto' }}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        type="email"
                        required
                    />
                    <TextField
                        label="Message"
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={4}
                        required
                    />
                    <Button variant="contained" color="primary" type="submit" sx={{ marginTop: 2 }}>
                        Send Message
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default Contact;
