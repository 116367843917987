// src/components/Avatar.tsx

import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

interface AvatarProps {
  seed: string;
  size?: number;
}

const Avatar: React.FC<AvatarProps> = ({ seed, size = 60 }) => {
  const avatarUrl = `https://api.dicebear.com/6.x/big-smile/svg?seed=${seed}`;

  return (
    <MuiAvatar
      src={avatarUrl}
      alt="User Avatar"
      sx={{ width: size, height: size }}
    />
  );
};

export default Avatar;
