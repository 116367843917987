import { User, UserAction } from './../types/index';
// import * as fs from 'fs';
const USER_DATABASE_FOLDER = "/database";

export class UserManager {
  private users: User[] = [];
  private readonly USER_FILE = USER_DATABASE_FOLDER + 'users.json';

  constructor() {
    this.loadUsers();
  }

  // private loadUsers() {
  //   try {
  //     const data = fs.readFileSync(this.USER_FILE, 'utf8');
  //     this.users = JSON.parse(data);
  //   } catch (err) {
  //     console.error('Error loading users:', err);
  //   }
  // }

  private loadUsers() {
    const storedUsers = localStorage.getItem(this.USER_FILE);
    if (storedUsers) {
      this.users = JSON.parse(storedUsers);
    }
  }


  private saveUsers() {
    localStorage.setItem(this.USER_FILE, JSON.stringify(this.users));
  }

  public addUser(user: Omit<User, 'id' | 'actionLog' | 'signupDate'>) {
    const newUser: User = {
      ...user,
      id: Date.now().toString(),
      actionLog: [],
      signupDate: new Date().toISOString(),
    };
    this.users.push(newUser);
    this.saveUsers();
    return newUser;
  }

  public signupUser(
    email: string, 
    name: string, 
    password: string,
    avatarSeed: number
  ): User {
      const newUser: User = {
        id: Date.now().toString(),
        email,
        name,
        status: 'lead',
        lastAction: 'signup',
        actionLog: [],
        signupDate: new Date().toISOString(),
        emailSeriesSent: [],
        purchaseCount: 0,
        totalPurchaseValue: 0,
        password,
        avatarSeed
      };
      this.users.push(newUser);
      this.saveUsers();
      return newUser;
  }

  public updateUserStatus(userId: string, newStatus: User['status']) {
    const user = this.users.find(u => u.id === userId);
    if (user) {
      user.status = newStatus;
      this.saveUsers();
    }
  }

  public logUserAction(userId: string, action: string) {
    const user = this.users.find(u => u.id === userId);
    if (user) {
      const userAction: UserAction = { action, timestamp: new Date().toISOString() };
      user.lastAction = action;
      user.actionLog.push(JSON.stringify(userAction));
      if (action === 'purchase') {
        user.lastPurchaseDate = userAction.timestamp;
      }
      this.saveUsers();
    }
  }

  public getUser(userId: string): User | undefined {
    return this.users.find(u => u.id === userId);
  }
  public getUserByEmail(email: string): User | undefined {
    return this.users.find(user => user.email === email);
  }
  public validateUser(email: string, password: string): boolean {
    const user = this.getUserByEmail(email);
    // In a real application, you'd use proper password hashing and comparison
    return user !== undefined && user.password === password;
  }
}

// Example usage in your website code
const userManager = new UserManager();

// When a new user signs up
const newUser = userManager.addUser({
  email: 'newuser@example.com',
  name: 'John Doe',
  status: 'lead',
  lastAction: 'signup',
  emailSeriesSent: [],
  purchaseCount: 0,
  totalPurchaseValue: 0,
  password: '',
  avatarSeed: 0
});

// When a user performs an action
userManager.logUserAction(newUser.id, 'download_whitepaper');

// When a user makes a purchase
userManager.logUserAction(newUser.id, 'purchase');
userManager.updateUserStatus(newUser.id, 'customer');