import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import curriculumData from '../data/french/365/curriculum.json';
import { Box, Button, IconButton, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { speakText } from '../utils/textToSpeech';
import { Chapter, Lesson, QuizQuestion } from '../types';

const Exercises: React.FC = () => {
  const { chapterId, lessonId } = useParams<{ chapterId: string; lessonId: string }>();
  const [score, setScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [feedback, setFeedback] = useState<string[]>([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<Set<number>>(new Set());

  const currentChapter = curriculumData.curriculum.find((ch): ch is Chapter => ch.chapterId === chapterId);
  const currentLesson = currentChapter?.lessons.find((les: Lesson) => les.lessonId === lessonId);
  
  const handleSpeak = (text: string) => {
    speakText(text, 'fr-FR', () => {}, () => {}, () => {});
  };

  const handleRadioChange = (questionIndex: number) => {
    setAnsweredQuestions(prev => {
      const newSet = new Set(prev);
      newSet.add(questionIndex);
      return newSet;
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let correctAnswers = 0;
    const feedbackArray: string[] = [];

    // Handle quiz questions
    currentLesson?.exercises.quiz.forEach((question: QuizQuestion, index: number) => {
      const userAnswer = formData.get(`quiz${index}`);
      if (parseInt(userAnswer as string) === question.answer) {
        correctAnswers++;
      } else {
        feedbackArray.push(`Quiz ${index + 1}: ${question.question} - Correct answer: ${question.options[question.answer]}`);
      }
    });

    setScore(correctAnswers);
    setTotalQuestions(currentLesson?.exercises.quiz.length || 0);
    setFeedback(feedbackArray);
    setSubmitted(true);
  };

  return (
    <Box sx={{ marginTop: 10, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Exercises: {currentLesson?.lessonTitle}</h1>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          {/* Quiz questions */}
          <Typography variant="h6">Exercises</Typography>
          {currentLesson?.exercises.quiz.map((question: QuizQuestion, index: number) => (
            <div key={`quiz${index}`}>
              <p>{question.question}
                <IconButton onClick={() => handleSpeak(question.question)} size="small">
                  <VolumeUpIcon />
                </IconButton>
              </p>
              <RadioGroup name={`quiz${index}`}>
                {question.options.map((option: string, i: number) => (
                  <FormControlLabel
                    key={i}
                    value={String(i)}
                    control={<Radio required onChange={() => handleRadioChange(index)} />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </div>
          ))}

          <Button style={{ marginTop: 40 }} variant="contained" color="primary" type="submit">Submit</Button>
        </form>
      ) : (
        <div>
          <h2>Your Score: {score} / {totalQuestions}</h2>
          {score < totalQuestions * 0.8 ? (
            <div>
              <p>You need to score at least 80% to proceed.</p>
              <p>Feedback:</p>
              <ul>
                {feedback.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <Link to={`/course/${chapterId}/${lessonId}`}>Try Again</Link>
            </div>
          ) : (
            <div>
              <p>Congratulations! You passed!</p>
              <Button component={Link} to={`/course/${chapterId}/${currentLesson?.lessonId}`} variant="contained" color="primary">Next Lesson</Button>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default Exercises;