import React, { useEffect } from 'react';
import { Box, Typography, Card,Grid, CardContent, Stack, Button } from '@mui/material';
import curriculumData from '../data/french/365/curriculum.json';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Chapter } from '../types';

import { CourseTable } from '../components/course/courseTable';

interface CurriculumData {
    curriculumTitle: string;
    curriculum: Chapter[];
}

const Curriculum: React.FC = () => {
    useEffect(() => {
        // Any necessary side effects can be handled here
    }, []);

    if (!curriculumData) {
        return <Typography variant="h6" align="center">Loading...</Typography>;
    }

    // Sort chapters by level
    const levelOrder = ['A1 Level', 'A2 Level', 'B1 Level', 'B2 Level'];
    
    // Group chapters by level
    const groupedChapters = curriculumData.curriculum.reduce((acc: Record<string, Chapter[]>, chapter: Chapter) => {
        if (!acc[chapter.level]) {
            acc[chapter.level] = [];
        }
        acc[chapter.level].push(chapter);
        return acc;
    }, {});

    // Sort levels based on the predefined order
    const sortedLevels = Object.keys(groupedChapters).sort((a, b) => {
        return levelOrder.indexOf(a) - levelOrder.indexOf(b);
    });

    return (
        <Box sx={{ marginTop:10, padding: 1 }}>
            
            <Typography variant="h4" align="center" gutterBottom>
                {curriculumData.curriculumTitle}
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
                Explore the curriculum structured by levels
            </Typography>
            
            

            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} key={"A1 Level"}>
                    <CourseTable
                        curriculum={curriculumData.curriculum}
                        level={"A1 Level"}
                    />
                
                </Grid>
                <Grid item xs={12} sm={6} key={"B1 Level"}>
                <CourseTable
                    curriculum={curriculumData.curriculum}
                    level={"B1 Level"}
                />
                
                </Grid>
            </Grid>
            
        </Box>
    );
};

export default Curriculum;
