// src/pages/Home.tsx

import React from 'react';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
    Stack,
    Container,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
    Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alpha, styled } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AvatarComponent from '../components/Avatar';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InstagramIcon from '@mui/icons-material/Instagram';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeacherIcon from '@mui/icons-material/School';
import InteractiveIcon from '@mui/icons-material/Gamepad';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SignupSection from '../components/landing/SignupSection';
import Avatar from '../components/Avatar';

// Styled components for the banners
const Banner = styled(Box)(({ theme }) => ({
    backgroundImage: 'url(/images/banner1.jpg)', // Use the relative path to the image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: theme.spacing(5),
    color: 'white',
    textAlign: 'center',
  }));

const FeatureCard = styled(Card)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
}));



const Home: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const plans = [
        { plan: 'Basic Plan', price: '$99', features: ['A2, B1 levels', 'Access to Basic Courses', 'interactive exercises'] },
        { plan: 'Premium Plan', price: '$199', features: ['A2, B1 levels', 'Access to All Courses', '1-on-1 Tutoring'] },
    ];
    
    const testimonials = [
        { name: 'John Doe', quote: 'This course changed my life!', seed: 'john' },
        { name: 'Jane Smith', quote: 'I learned so much in just a few weeks.', seed: 'jane' },
    ];
    const features = [
        { title: 'Comprehensive Material', text: 'All the resources you need to succeed.', icon: LibraryBooksIcon },
        { title: 'Community Support', text: 'Join a thriving community of learners.', icon: PeopleIcon },
        { title: 'Expert Instructors', text: 'Learn from industry professionals.', icon: SchoolIcon },
        { title: 'Flexible Learning', text: 'Study at your own pace, anytime.', icon: AccessTimeIcon },
    ];
    const faqs = [
        { question: 'What is the duration of the course?', answer: 'The course lasts for 120 days, providing ample time for comprehensive learning and practice.' },
        { question: 'Do I need prior knowledge of French?', answer: 'No, this course is designed for beginners. We start from the basics and gradually build your language skills.' },
        { question: 'How much time should I dedicate daily?', answer: 'We recommend dedicating at least 30 minutes to 1 hour daily for optimal learning progress.' },
        { question: 'Is there a mobile app available?', answer: 'Yes, we have a mobile app available for both iOS and Android devices, allowing you to learn on-the-go.' },
      ];
    
    const stats = [
        { icon: SchoolIcon, value: '500+', label: 'Enrolled Students' },
        { icon: GroupIcon, value: '50+', label: 'Expert Instructors' },
        { icon: StarIcon, value: '4.8', label: 'Average Rating' },
      ];

      
    return (
        <div>
  
            <Box
                id="hero"
                sx={(theme) => ({
                    width: '100%',
                    backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                    backgroundSize: '100% 20%',
                    backgroundRepeat: 'no-repeat',
                })}
                >
                <Container
                    maxWidth="lg"
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                    }}
                >
                    <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                        variant="h1"
                        sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                        fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        Master french from A2 to B2 in 200 days! 
                        
                    </Typography>
                    
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                    >
                        {/* Interactive courses,  */}
                    </Typography>
                    
                    </Stack>
                    
                </Container>
                </Box>
    
            {/* Banner Section */}
            <Box
                sx={{
                    backgroundColor: '#3f51b5',
                    color: 'white',
                    py: { xs: 6, md: 10 },
                    backgroundImage: 'linear-gradient(45deg, #3f51b5 30%, #5c6bc0 90%)',
                }}
                >
                <Container maxWidth="lg">
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                    >
                    <SchoolIcon sx={{ fontSize: 60, mb: 2 }} />
                    <Typography 
                        variant="h3" 
                        component="h1" 
                        gutterBottom 
                        sx={{ 
                        fontWeight: 'bold',
                        mb: 3,
                        fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
                        }}
                    >
                        Interactive Courses
                    </Typography>
                    <Typography 
                        variant="h5" 
                        gutterBottom
                        sx={{ 
                        mb: 4,
                        maxWidth: '800px',
                        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.7rem' }
                        }}
                    >
                        Dive into our comprehensive curriculum with over 200 engaging lessons!
                    </Typography>
                    <Button
                        component={Link}
                        to="/curriculum"
                        variant="contained"
                        size="large"
                        sx={{
                        backgroundColor: 'white',
                        color: '#3f51b5',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                        px: 4,
                        py: 1.5,
                        fontSize: '1.1rem',
                        }}
                    >
                        Explore Course Curriculum
                    </Button>
                    </Box>
                </Container>
            </Box>

            {/* Features Section */}
            
            {/* <Box id="features" sx={{ padding: 10 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Features
                </Typography>
                <Grid container spacing={2}>
                    {[
                        { icon: <TeacherIcon fontSize="large" />, title: 'Expert Instructors', description: 'Learn from experienced teachers.' },
                        { icon: <ScheduleIcon fontSize="large" />, title: 'Interactive Lessons', description: 'Engaging and interactive content.' },
                        { icon: <TeacherIcon fontSize="large" />, title: 'Flexible Schedule', description: 'Learn at your own pace.' },
                    ].map((feature, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <FeatureCard>
                                <CardContent>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                        {feature.icon}
                                      
                                        <Typography variant="h6">{feature.title}</Typography>
                                        <Typography variant="body2">{feature.description}</Typography>
                                    </Box>
                                </CardContent>
                            </FeatureCard>
                        </Grid>
                    ))}
                </Grid>
            </Box> */}

            {/* Why Our Course Section */}
            <Box id="features" sx={{ padding: { xs: 4, md: 10 }, backgroundColor: '#f5f5f5' }}>
                <Typography variant="h4" align="center" gutterBottom fontWeight="bold" color="primary">
                    Why Choose Our Course?
                </Typography>
                <Typography variant="subtitle1" align="center" paragraph sx={{ mb: 6 }}>
                    Discover the advantages that set our language course apart
                </Typography>
                <Grid container spacing={4}>
                    {features.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card 
                        elevation={3}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s',
                            '&:hover': {
                            transform: 'translateY(-5px)',
                            },
                        }}
                        >
                        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Icon component={item.icon} fontSize="large" color="primary" sx={{ mb: 2 }} />
                            <Typography variant="h6" gutterBottom fontWeight="bold">
                            {item.title}
                            </Typography>
                            <Typography variant="body1">
                            {item.text}
                            </Typography>
                        </CardContent>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Pricing Plans Section */}
            <Box id="pricing" sx={{ padding: 10, backgroundColor: '#f5f5f5' }}>
            <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
                Pricing Plans
            </Typography>
                <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
                    {plans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card 
                        elevation={4}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            '&:hover': {
                            transform: 'translateY(-10px)',
                            boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
                            },
                        }}
                        >
                        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h5" component="h2" gutterBottom fontWeight="bold" color="primary">
                            {plan.plan}
                            </Typography>
                            <Typography variant="h3" component="div" gutterBottom fontWeight="bold">
                            {plan.price}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                            per month
                            </Typography>
                            <List sx={{ mt: 2, mb: 2 }}>
                            {plan.features.map((feature, i) => (
                                <ListItem key={i} disableGutters>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={feature} />
                                </ListItem>
                            ))}
                            </List>
                            <Box sx={{ mt: 'auto' }}>
                            <Button variant="contained" color="primary" fullWidth>
                                Choose Plan
                            </Button>
                            </Box>
                        </CardContent>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Testimonials Section */}
            <Box id="testimonials" sx={{ padding: 10, backgroundColor: '#f5f5f5' }}>
                <Typography variant="h4" align="center" gutterBottom fontWeight="bold">
                    What Our Students Say
                </Typography>
                <Grid container spacing={4} sx={{ mt: 4 }}>
                    {testimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Card 
                        elevation={3}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'transform 0.3s',
                            '&:hover': {
                            transform: 'translateY(-5px)',
                            },
                        }}
                        >
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Avatar seed={testimonial.seed} size={80} />
                            <Box sx={{ mt: 2, mb: 2 }}>
                            <FormatQuoteIcon color="primary" fontSize="large" />
                            </Box>
                            <Typography variant="body1" paragraph>
                            "{testimonial.quote}"
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold" color="primary">
                            {testimonial.name}
                            </Typography>
                        </CardContent>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Box>

            {/* FAQ Section */}
            <Box id="faq" sx={{ padding: { xs: 4, md: 10 }, backgroundColor: '#f5f5f5' }}>
                <Container maxWidth="md">
                    <Typography variant="h4" align="center" gutterBottom fontWeight="bold" color="primary">
                    Frequently Asked Questions
                    </Typography>
                    <Typography variant="subtitle1" align="center" paragraph sx={{ mb: 6 }}>
                    Find answers to common questions about our language course
                    </Typography>
                    {faqs.map((faq, index) => (
                    <Accordion 
                        key={index} 
                        sx={{
                        mb: 2,
                        boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
                        '&:before': { display: 'none' },
                        '&.Mui-expanded': { mt: 0 }
                        }}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, .03)',
                            borderBottom: '1px solid rgba(0, 0, 0, .125)',
                            '&.Mui-expanded': {
                            minHeight: 56,
                            },
                        }}
                        >
                        <Typography fontWeight="bold">{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    ))}
                </Container>
            </Box>

            {/* About Section */}
            <Box id="about" sx={{ padding: { xs: 4, md: 10 }, backgroundColor: '#f5f5f5' }}>
                <Container maxWidth="lg">
                <Typography variant="h4" align="center" gutterBottom fontWeight="bold" color="primary">
                    About Us
                </Typography>
                <Typography variant="subtitle1" align="center" paragraph sx={{ mb: 6 }}>
                    Empowering language learners worldwide with innovative and effective courses
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {stats.map((stat, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <stat.icon sx={{ fontSize: 40, color: 'primary.main', mb: 2 }} />
                        <Typography variant="h4" component="div" gutterBottom fontWeight="bold">
                            {stat.value}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {stat.label}
                        </Typography>
                        </Paper>
                    </Grid>
                    ))}
                </Grid>
                </Container>
            </Box>

            <Box id="social" sx={{ 
                padding: { xs: 4, md: 10 }, 
                textAlign: 'center',
                backgroundImage: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                color: 'white'
            }}>
                <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom fontWeight="bold">
                    Join Our Community
                </Typography>
                <Typography variant="subtitle1" paragraph sx={{ mb: 4 }}>
                    Follow us on Instagram for daily language tips, success stories, and exclusive offers!
                </Typography>
                <Button 
                    variant="contained" 
                    size="large"
                    startIcon={<InstagramIcon />}
                    sx={{ 
                    backgroundColor: 'white',
                    color: '#FE6B8B',
                    '&:hover': {
                        backgroundColor: '#f3f3f3',
                    }
                    }}
                >
                    Follow Us on Instagram
                </Button>
                </Container>
            </Box>

            <SignupSection />
            
      

        </div>
    );
};

export default Home;
