import React, { useState } from 'react';
import { speakText } from '../../utils/textToSpeech';
import { Word } from '../../types';
import styles from './Flashcard.module.css';

interface Props {
  word: Word;
  onResult: (result: 'correct' | 'incorrect') => void;
}

const FlashcardComponent: React.FC<Props> = ({ word, onResult }) => {
  const [showBack, setShowBack] = useState(false);

  const handleSpeak = () => {

    speakText(word.french, 'fr-FR', () => {}, () => {}, () => {})
  };

  const handleFlip = () => {
    setShowBack(!showBack);
  };

  const handleResult = (result: 'correct' | 'incorrect') => {
    onResult(result);
    setShowBack(false); // Reset to the front side when giving feedback
  };

  return (
    <div className={styles.flashcardContainer}>
      <div 
        className={`${styles.flashcard} ${showBack ? styles.flipped : ''}`} 
        onClick={handleFlip}
      >
        <div className={styles.front}>
          <h2>{word.french}</h2>
          <button 
            className={styles.speakButton} 
            onClick={(e) => { 
              e.stopPropagation(); 
              handleSpeak(); 
            }}
          >
            🔊
          </button>
        </div>
        <div className={styles.back}>
          <h2>{word.english}</h2>
          <p className={styles.usage}>{word.usage}</p>
        </div>
      </div>
      <div className={styles.buttons}>
        <button 
          className={styles.knewButton} 
          onClick={() => handleResult('correct')}
        >
          I knew this
        </button>
        <button 
          className={styles.didntKnowButton} 
          onClick={() => handleResult('incorrect')}
        >
          I didn't know this
        </button>
      </div>
    </div>
  );
};

export default FlashcardComponent;
