// src/components/SignupSection.tsx
import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import Avatar from '../Avatar';

const SignupSection: React.FC = () => {
  const [email, setEmail] = useState('');
  const [avatarSeed, setAvatarSeed] = useState(Math.random().toString(36).substring(7));
  const [alertInfo, setAlertInfo] = useState<{ message: string; severity: 'success' | 'error' } | null>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleRandomizeAvatar = () => {
    setAvatarSeed(Math.random().toString(36).substring(7));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/email_list/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          avatar: avatarSeed,
          name: email.split("@")[0]
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      setAlertInfo({ message: 'Submission successful!', severity: 'success' });
      
      // Reset form
      setEmail('');
      setAvatarSeed(Math.random().toString(36).substring(7));
    } catch (error) {
      console.error('Error submitting data:', error);
      setAlertInfo({ message: 'Error submitting data. Please try again.', severity: 'error' });
    }

    
    console.log('Submitted:', { email, avatarSeed });
    // Reset form
    setEmail('');
    setAvatarSeed(Math.random().toString(36).substring(7));
  };

  return (
    <Box component="section" sx={{ my: 4, textAlign: 'center' }}>
      {alertInfo && (
        <Alert icon={false} severity={alertInfo.severity} onClose={() => setAlertInfo(null)}>
          {alertInfo.message}
        </Alert>
      )}
      <Typography variant="h4" component="h2" gutterBottom>
        Sign Up for Updates
      </Typography>
      <form onSubmit={handleSubmit}>
        

        <TextField
          type="email"
          label="Email Address"
          value={email}
          onChange={handleEmailChange}
          required
          sx={{ width: '100%', maxWidth: 400, mb: 2 }}
        />
        
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <Avatar seed={avatarSeed} size={60} />
          <Button onClick={handleRandomizeAvatar} sx={{ ml: 2 }}>
            Randomize Avatar
          </Button>
        </Box>

        

        <Button type="submit" variant="contained" color="primary">
          Sign Up
        </Button>
      </form>
    </Box>
  );
};

export default SignupSection;
