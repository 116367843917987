// Notification.tsx

import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const Notification: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const notificationData = localStorage.getItem('notification');
    if (notificationData) {
      const { type, message } = JSON.parse(notificationData);
      setType(type);
      setMessage(message);
      setOpen(true);
      localStorage.removeItem('notification');
    }
  }, []);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
