import React from 'react';
import { dailyActivities } from '../data/french/vocabularies/french/dailyActivities';
import { food } from '../data/french/vocabularies/french/food';
import { travel } from '../data/french/vocabularies/french/travel';
import { officeWork } from '../data/french/vocabularies/french/officeWork';

interface Props {
  onSelect: (vocabulary: string) => void;
}

// Define the vocabularies object
export const vocabularies = {
    officeWork,
    travel,
    food,
    dailyActivities
  };

  interface Props {
    onSelect: (vocabulary: string) => void;
  }

export const VocabularySelector: React.FC<Props> = ({ onSelect }) => {
  return (
    <div>
      <h2>Select a Vocabulary Topic</h2>
      {Object.keys(vocabularies).map((vocab) => (
        <button key={vocab} onClick={() => onSelect(vocab)}>
          {vocab}
        </button>
      ))}
    </div>
  );
};
