const MAX_CHUNK_LENGTH = 200;

export function splitTextIntoChunks(text: string): string[] {
  // Split text into phrases using punctuation as delimiters
  const phrases = text.match(/[^.!?]+[.!?]+|\S+/g) || [];
  const chunks: string[] = [];
  let currentChunk = '';

  phrases.forEach(phrase => {
    const trimmedPhrase = phrase.trim();
    if ((currentChunk + ' ' + trimmedPhrase).length <= MAX_CHUNK_LENGTH) {
      currentChunk += (currentChunk ? ' ' : '') + trimmedPhrase;
    } else {
      if (currentChunk) {
        chunks.push(currentChunk);
      }
      // If a single phrase is longer than MAX_CHUNK_LENGTH, split it
      if (trimmedPhrase.length > MAX_CHUNK_LENGTH) {
        const words = trimmedPhrase.split(' ');
        let phraseChunk = '';
        words.forEach(word => {
          if ((phraseChunk + ' ' + word).length <= MAX_CHUNK_LENGTH) {
            phraseChunk += (phraseChunk ? ' ' : '') + word;
          } else {
            chunks.push(phraseChunk);
            phraseChunk = word;
          }
        });
        if (phraseChunk) {
          currentChunk = phraseChunk;
        }
      } else {
        currentChunk = trimmedPhrase;
      }
    }
  });

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
}

function getBestVoice(lang: string) {
  const voices = speechSynthesis.getVoices();
  console.log('Available voices:', voices);
  
  // Try to find an exact match first
  let voice = voices.find(v => v.lang === lang);
  
  // If no exact match, try to find a voice that starts with the language code
  if (!voice) {
    voice = voices.find(v => v.lang.startsWith(lang.split('-')[0]));
  }
  
  // If still no match, try to find any voice with 'native' in its name
  if (!voice) {
    voice = voices.find(v => v.name.toLowerCase().includes('native'));
  }
  
  // If all else fails, just use the first available voice
  if (!voice && voices.length > 0) {
    voice = voices[0];
  }
  
  console.log('Selected voice:', voice);
  return voice;
}

// export function speakText(text: string, lang: string = 'fr-FR', onHighlight: (index: number, length: number) => void): Promise<void> {
//   return new Promise((resolve, reject) => {
//     const chunks = splitTextIntoChunks(text);
//     let currentChunkIndex = 0;

//     function speakNextChunk() {
//       if (currentChunkIndex >= chunks.length) {
//         resolve();
//         return;
//       }

//       const utterance = new SpeechSynthesisUtterance(chunks[currentChunkIndex]);
//       utterance.lang = lang;

//       // const voice = getBestVoice(lang)

//       utterance.onboundary = (event) => {
//         if (event.name === 'word') {
//           const chunkStart = chunks.slice(0, currentChunkIndex).join(' ').length + (currentChunkIndex > 0 ? 1 : 0);
//           onHighlight(chunkStart + event.charIndex, event.charLength);
//         }
//       };

//       utterance.onend = () => {
//         currentChunkIndex++;
//         speakNextChunk();
//       };

//       utterance.onerror = (event) => {
//         console.error('Speech synthesis error:', event);
//         reject(new Error('Speech synthesis failed'));
//       };

//       speechSynthesis.speak(utterance);
//     }

//     speakNextChunk();
//   });
// }

export function speakText(
  text: string, 
  lang: string = 'fr-FR', 
  onHighlight: (start: number, end: number) => void,
  onChunkStart: (chunkIndex: number) => void,
  onChunkEnd: (chunkIndex: number) => void
): Promise<void> {
  return new Promise((resolve, reject) => {
    const cleanText = text.replace(/_/g, '.').replace(/'/g, "'");
    const chunks = splitTextIntoChunks(cleanText);

    if (!('speechSynthesis' in window)) {
      console.error('Text-to-speech not supported in this browser.');
      reject(new Error('Text-to-speech not supported'));
      return;
    }

    // Cancel any ongoing speech
    speechSynthesis.cancel();

    let currentChunkIndex = 0;

    function speakNextChunk() {
      if (currentChunkIndex >= chunks.length) {
        
        resolve();
        return;
      }

      const utterance = new SpeechSynthesisUtterance(chunks[currentChunkIndex]);
      utterance.lang = lang;
      let wordIndex = 0;
      const words = chunks[currentChunkIndex].split(' ');


      utterance.onboundary = (event) => {
        if (event.name === 'word') {
          const chunkStart = chunks.slice(0, currentChunkIndex).join(' ').length + (currentChunkIndex > 0 ? 1 : 0);
          const wordStart = words.slice(0, wordIndex).join(' ').length + (wordIndex > 0 ? 1 : 0);
          const start = chunkStart + wordStart;
          const end = start + words[wordIndex].length;
          onHighlight(start, end);
          wordIndex++;
        }
      };

      utterance.onstart = () => {
        onChunkStart(currentChunkIndex);
      };

      utterance.onend = () => {
        onChunkEnd(currentChunkIndex);
        currentChunkIndex++;
        speakNextChunk();
      };

      utterance.onerror = (event) => {
        console.error('Speech synthesis error:', event);
        reject(new Error('Speech synthesis failed'));
      };

      // Attempt to speak
      try {
        speechSynthesis.speak(utterance);
        // console.log(`Started speaking chunk ${currentChunkIndex + 1} of ${chunks.length}`);
      } catch (error) {
        console.error('Error when calling speechSynthesis.speak:', error);
        reject(new Error('Failed to start speech synthesis'));
        return;
      }

      // Check if speech has started after a short delay
      setTimeout(() => {
        if (!speechSynthesis.speaking) {
          console.warn('Speech synthesis did not start. Retrying...');
          speechSynthesis.cancel();
          try {
            speechSynthesis.speak(utterance);
          } catch (error) {
            console.error('Error when retrying speechSynthesis.speak:', error);
            reject(new Error('Failed to start speech synthesis on retry'));
          }
        }
      }, 250);
    }

    speakNextChunk();
  });
}
