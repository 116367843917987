// src/components/Footer.tsx

import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
    const handleHomepageSectionClick = (section: string) => {
        const element = document.getElementById(section);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: '#f5f5f5',
                color: 'text.secondary',
                py: 6,
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="space-between">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Language Course
                        </Typography>
                        <Typography variant="body2">
                            Empowering language learners worldwide with innovative and effective courses.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Quick Links
                        </Typography>
                        <Link component="button" variant="body2" onClick={() => handleHomepageSectionClick('features')} display="block">Features</Link>
                        <Link component="button" variant="body2" onClick={() => handleHomepageSectionClick('why-our-course')} display="block">Why Our Course</Link>
                        <Link component="button" variant="body2" onClick={() => handleHomepageSectionClick('faq')} display="block">FAQ</Link>
                        <Link component="button" variant="body2" onClick={() => handleHomepageSectionClick('about')} display="block">About</Link>
                        <Link component={RouterLink} to="/contact" color="inherit" display="block">Contact</Link>
                        <Link component={RouterLink} to="/curriculum" color="inherit" display="block">Curriculum</Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Connect With Us
                        </Typography>
                        <IconButton aria-label="Facebook" color="inherit">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton aria-label="Twitter" color="inherit">
                            <TwitterIcon />
                        </IconButton>
                        <IconButton aria-label="Instagram" color="inherit">
                            <InstagramIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        © {new Date().getFullYear()} Language Course. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
