// src/components/Course.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import curriculumData from '../data/french/365/curriculum.json';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { speakText, splitTextIntoChunks } from '../utils/textToSpeech';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Chapter, Lesson } from './../types/index';
// import Slideshow from '../components/Slideshow';

const Course: React.FC = () => {
  const textRef = useRef<HTMLDivElement>(null);
  
  const { chapterId, lessonId } = useParams<{ chapterId: string; lessonId: string }>();
  const [currentChapter, setCurrentChapter] = useState<Chapter | null>(null);
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(null);

  const [highlightedText, setHighlightedText] = useState<{ start: number; end: number } | null>(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState<number | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);


  
  useEffect(() => {
    const chapter = curriculumData.curriculum.find(ch => ch.chapterId === chapterId);
    if (chapter) {
      setCurrentChapter(chapter as Chapter);
      const lessonIndex = chapter
        .lessons.findIndex(les => les.lessonId === lessonId)

      const lesson = chapter.lessons[lessonIndex];
      if (lesson) {
        setCurrentLesson(lesson as unknown as Lesson );
        setCurrentSlideIndex(0); // Reset slide index when lesson changes
      } else {
        setCurrentLesson(null);
      }
    } else {
      setCurrentChapter(null);
    }
  }, [chapterId, lessonId]);

  useEffect(() => {
    if (currentLesson?.material && currentLesson.material[currentSlideIndex]) {
      handleSpeak(currentLesson.material[currentSlideIndex].transcript);
    }
  }, [currentSlideIndex, currentLesson]);

  if (!currentChapter || !currentLesson) {
    return (
      <Box sx={{ marginTop:10, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      
        Loading...
      
      </Box>)
  }

  const handleNextSlide = () => {
    if (currentLesson.material && currentSlideIndex < currentLesson.material.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };


  const handleSpeak = (text: string) => {
    if (utterance) {
      speechSynthesis.cancel(); // Cancel any ongoing speech
    }

    setIsPlaying(true);
    setIsPaused(false);
    setHighlightedText(null);

    speakText(
      text,
      'fr-FR',
      (start, end) => setHighlightedText({ start, end }),
      (chunkIndex) => setCurrentChunkIndex(chunkIndex),
      (chunkIndex) => {
        if (chunkIndex === splitTextIntoChunks(text).length - 1) {
          setIsPlaying(false);
          setIsPaused(false);
          setHighlightedText(null);
          setCurrentChunkIndex(null);
        }
      }
    ).catch(error => {
      console.error("Speech error:", error);
      setIsPlaying(false);
      setIsPaused(false);
      setHighlightedText(null);
      setCurrentChunkIndex(null);
      // alert(`Speech synthesis failed: ${error.message}`);
    });
  };

  const handlePause = () => {
    if (isPlaying && !isPaused) {
      speechSynthesis.pause();
      setIsPaused(true);
    }
  };

  const handleResume = () => {
    if (isPlaying && isPaused) {
      speechSynthesis.resume();
      setIsPaused(false);
    }
  };

  const renderHighlightedText = (text: string) => {
    if (!highlightedText) {
      return text;
    }

    const chunks = splitTextIntoChunks(text);
    
    console.log("highlightedText" , highlightedText)
    const before = text.slice(0, highlightedText.start);
    const highlighted = text.slice(highlightedText.start, highlightedText.end);
    const after = text.slice(highlightedText.end);

    return (
      <>
        {before}
          <span style={{ backgroundColor: 'yellow' }}>{highlighted}</span>
        {after}
      </>
    );
  };

  const renderContent = () => {
    if (currentLesson.material && currentLesson.material.length > 0) {
      const currentSlide = currentLesson.material[currentSlideIndex];
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', 
                    width: '100%', marginTop: 2, 
                    minHeight: '200px' }}>
          <Box sx={{ width: '68%' }}>
          <img 
            src={currentSlide.imageUrl} 
            alt="Lesson visual" 
            style={{ width: '100%', height: 'auto' }} 
          />

          </Box>
          <Box sx={{ width: '28%' }}>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              <IconButton onClick={handlePause} disabled={!isPlaying || isPaused}>
                <PauseIcon />
              </IconButton>
              <IconButton onClick={handleResume} disabled={!isPlaying || !isPaused}>
                <PlayArrowIcon />
              </IconButton>
            </Box>

            <Typography variant="body1" ref={textRef}>
              {renderHighlightedText(currentSlide.transcript)}
            </Typography>

            <IconButton onClick={() => handleSpeak(currentSlide.transcript)} size="small">
              <VolumeUpIcon />
            </IconButton>

          </Box>
        </Box>
      );
    } 
    else if(currentLesson.lessonText && currentLesson.lessonText.length > 0){
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Typography 
            variant="body1" 
            ref={textRef} 
            sx={{ whiteSpace: 'pre-line' }}
          >
            {currentLesson.lessonText}
          </Typography>
        </Box>
      )
    }
    else {
      return (
        <iframe
          width="560"
          height="315"
          src={currentLesson.videoUrl.replace("watch?v=", "embed/")}
          title={currentLesson.lessonTitle}
          frameBorder="0"
          allowFullScreen
          style={{ marginBottom: '20px' }}
        ></iframe>
      );
    }
  };

  return (
    <Box sx={{ marginTop:10, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h3" gutterBottom>
        {currentChapter.chapterTitle}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {currentLesson.lessonTitle}
      </Typography>

      <Typography variant="body1" gutterBottom>
        {currentLesson.description}
        <IconButton onClick={() => handleSpeak(currentLesson.description)} size="small">
          <VolumeUpIcon />
        </IconButton>
      </Typography>

      {renderContent()}
      
      {currentLesson.material && currentLesson.material.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 2 }}>
          <Button 
            onClick={handlePreviousSlide} 
            disabled={currentSlideIndex === 0}
            startIcon={<ArrowBackIcon />}
          >
            Previous
          </Button>
          <Button 
            onClick={handleNextSlide} 
            disabled={currentSlideIndex === currentLesson.material.length - 1}
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </Box>
      )}

          
        <Button
          component={Link}
          to={`/course/${currentChapter.chapterId}/${currentLesson.lessonId}/quiz`}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          Go to Quiz
        </Button>
    </Box>
  );
};

export default Course;
