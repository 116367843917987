import React from 'react';

import { useUserProgress } from '../hooks/useUserProgress';
import { Word } from '../types';

interface Props {
  userId: string;
  words: Word[];
}

export const ReviewScheduler: React.FC<Props> = ({ userId, words }) => {
  const { scheduledReviews, completeReview } = useUserProgress(userId);

  return (
    <div>
      <h2>Scheduled Reviews</h2>
      {scheduledReviews.map((review) => (
        <div key={review.wordId}>
          <p>{words.find((w) => w.id === review.wordId)?.french}</p>
          <p>Review on: {review.nextReviewDate.toLocaleDateString()}</p>
          <button onClick={() => completeReview(review.wordId)}>Complete Review</button>
        </div>
      ))}
    </div>
  );
};
