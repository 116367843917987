import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button, Box, Container, Divider, Drawer, MenuItem, PaletteMode } from '@mui/material';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import LoginButton from '../auth/LoginButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Padding } from '@mui/icons-material';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

const StyledAppBar = styled(AppBar)({
    background: 'rgba(255, 255, 255, 0.)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
});

const logoStyle = {
    width: '40px',
    height: 'auto',
    cursor: 'pointer',
    borderRadius: '50%',
  };


const circleStyle = {
    width: '47px', // Width of the circle (adjust as needed)
    height: '47px', // Height of the circle (adjust as needed)
    borderRadius: '50%', // Make it circular
    border: '2px solid black', // Green border
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  interface HeaderProps {
  }

const Header: React.FC<HeaderProps> = ( mode, toggleColorMode ) => {
    const navigate = useNavigate(); // Initialize useNavigate
    
    const handleHomepageSectionClick = (section:string) => {
        navigate('/'); // Navigate to home page
        setTimeout(() => {
            scrollToSection(section); // Scroll to the features section after navigation
        }, 100); // Delay to allow navigation to complete
    };
    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean | ((prevState: boolean) => boolean)) => () => {
        setOpen(newOpen);
      };

    return (
        <AppBar
        position="fixed"
        sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 3 }}
      >
        <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
          
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
              <Box sx={circleStyle}> {/* Circle around the image */}
                    <img
                    src='/images/logo_fluent_french95.jpg' // Use the local image path
                    style={logoStyle}
                    alt="logo of sitemark"
                    />
              </Box>

                <Typography variant="h6" color="text.primary" sx={{ mr: 2, marginLeft:1 }}>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    fluent_french95
                  </Link>
                </Typography>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              
                <Button onClick={() => handleHomepageSectionClick('features')} 
                  variant="text" color="info" size="small">
                  Features
                </Button>

                <Button onClick={() => handleHomepageSectionClick('testimonials')} 
                  variant="text" color="info" size="small">
                  Testimonials
                </Button>
                
                <Button onClick={() => handleHomepageSectionClick('pricing')} 
                  variant="text" color="info" size="small">
                  Pricing
                </Button>

                <Button onClick={() => handleHomepageSectionClick('faq')} 
                  variant="text" color="info" size="small">
                  FAQ
                </Button>

                <MenuItem
                    sx={{ py: '6px', px: '12px' }}
                >
                    <Link to="/curriculum" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2" color="text.primary">
                            CURRICULUM
                        </Typography>
                    </Link>
                </MenuItem>

                <MenuItem
                    sx={{ py: '6px', px: '12px' }}
                >
                    <Link to="/vocabulary" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2" color="text.primary">
                            VOCABULARY
                        </Typography>
                    </Link>
                </MenuItem>

              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              
              <Button component={Link} to="/login" style={{ textDecoration: 'none' }}
                  color="primary"
                  variant="text"
                  size="small"
                  target="_blank"
                >
                  Log in
                </Button>
              
                <Button
                  to="/signup" style={{ textDecoration: 'none' }}
                  color="primary"
                  variant="contained"
                  size="small"
                  component={Link}
                  target="_blank"
                >
                  Sign up
                </Button>
              
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    {/* <ToggleColorMode 
                        mode={mode} 
                        toggleColorMode={toggleColorMode} /> */}
                  </Box>
                  <MenuItem onClick={() => handleHomepageSectionClick('features')}>
                    Features
                  </MenuItem>
                  <MenuItem onClick={() => handleHomepageSectionClick('testimonials')}>
                    Testimonials
                  </MenuItem>
                  
                  <MenuItem onClick={() => handleHomepageSectionClick('pricing')}>
                    Pricing
                  </MenuItem>
                  <MenuItem onClick={() => handleHomepageSectionClick('faq')}>FAQ</MenuItem>
                  
                  <Divider />

                  <MenuItem  >
                    <Link to="/curriculum" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2" color="text.primary">
                            Curriculum
                        </Typography>
                    </Link>
                   </MenuItem>

                   <MenuItem  >
                    <Link to="/vocabulary" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2" color="text.primary">
                            Vocabulary
                        </Typography>
                    </Link>
                   </MenuItem>
                  <Divider />


                  <MenuItem>
                  <Button component={Link} to="/login" style={{ textDecoration: 'none' }}
                    
                      color="primary"
                      variant="contained"
                      
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Sign up
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button component={Link} to="/login" style={{ textDecoration: 'none' }}
                        color="primary"
                        variant="outlined"
                        target="_blank"
                        sx={{ width: '100%' }}
                      >
                        Log in
                      </Button>
                  </MenuItem>
                  

                </Box>
              </Drawer>
            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>
    );
};

export default Header;