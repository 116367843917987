import { Word } from '../../../../types';

export const officeWork: Word[] = [
  { 
    id: '1', 
    french: 'bureau', 
    english: 'office', 
    usage: 'Je vais au bureau tous les jours.' // I go to the office every day.
  },
  { 
    id: '2', 
    french: 'ordinateur', 
    english: 'computer', 
    usage: 'Mon ordinateur est très rapide.' // My computer is very fast.
  },
  { 
    id: '3', 
    french: 'réunion', 
    english: 'meeting', 
    usage: 'La réunion commence à 10 heures.' // The meeting starts at 10 AM.
  },
  { 
    id: '4', 
    french: 'collègue', 
    english: 'colleague', 
    usage: 'Mon collègue est très sympathique.' // My colleague is very friendly.
  },
  { 
    id: '5', 
    french: 'téléphone', 
    english: 'telephone', 
    usage: 'Je dois répondre au téléphone.' // I need to answer the telephone.
  },
  { 
    id: '6', 
    french: 'email', 
    english: 'email', 
    usage: `J'ai reçu un email important.` // I received an important email.
  },
  { 
    id: '7', 
    french: 'document', 
    english: 'document', 
    usage: 'Le document est sur mon bureau.' // The document is on my desk.
  },
  { 
    id: '8', 
    french: 'agenda', 
    english: 'agenda', 
    usage: `J'ai noté la réunion dans mon agenda.` // I noted the meeting in my agenda.
  },
  { 
    id: '9', 
    french: 'projet', 
    english: 'project', 
    usage: 'Nous travaillons sur un nouveau projet.' // We are working on a new project.
  },
  { 
    id: '10', 
    french: 'rapport', 
    english: 'report', 
    usage: 'Le rapport doit être terminé demain.' // The report must be finished by tomorrow.
  },
  { 
    id: '11', 
    french: 'imprimante', 
    english: 'printer', 
    usage: 'L’imprimante est en panne.' // The printer is out of order.
  },
  { 
    id: '12', 
    french: 'fichier', 
    english: 'file', 
    usage: 'Où est le fichier que tu as envoyé?' // Where is the file you sent?
  },
  { 
    id: '13', 
    french: 'pause', 
    english: 'break', 
    usage: 'Nous avons une pause de 15 minutes.' // We have a 15-minute break.
  },
  { 
    id: '14', 
    french: 'tableau', 
    english: 'board', 
    usage: 'Écris cela sur le tableau.' // Write that on the board.
  },
  { 
    id: '15', 
    french: 'dossier', 
    english: 'folder', 
    usage: 'Le dossier est sur l’étagère.' // The folder is on the shelf.
  },
  { 
    id: '16', 
    french: 'salle de conférence', 
    english: 'conference room', 
    usage: 'La salle de conférence est au deuxième étage.' // The conference room is on the second floor.
  },
  { 
    id: '17', 
    french: 'tâche', 
    english: 'task', 
    usage: `J'ai beaucoup de tâches à accomplir.` // I have many tasks to complete.
  },
  { 
    id: '18', 
    french: 'pause déjeuner', 
    english: 'lunch break', 
    usage: 'Nous avons une pause déjeuner à midi.' // We have a lunch break at noon.
  },
  { 
    id: '19', 
    french: 'présentation', 
    english: 'presentation', 
    usage: 'La présentation est prévue pour demain.' // The presentation is scheduled for tomorrow.
  },
  { 
    id: '20', 
    french: 'table', 
    english: 'table', 
    usage: 'La table de réunion est grande.' // The meeting table is large.
  },
  { 
    id: '21', 
    french: 'ordinateur portable', 
    english: 'laptop', 
    usage: `J'utilise mon ordinateur portable pour travailler.` // I use my laptop to work.
  },
  { 
    id: '22', 
    french: 'système', 
    english: 'system', 
    usage: 'Le système doit être mis à jour.' // The system needs to be updated.
  },
  { 
    id: '23', 
    french: 'logiciel', 
    english: 'software', 
    usage: 'Le logiciel est très utile pour notre travail.' // The software is very useful for our work.
  },
  { 
    id: '24', 
    french: 'équipe', 
    english: 'team', 
    usage: 'Nous travaillons en équipe sur ce projet.' // We are working as a team on this project.
  },
  { 
    id: '25', 
    french: 'client', 
    english: 'client', 
    usage: `Le client a besoin d'une réponse rapide.` // The client needs a quick response.
  },
];

