// src/components/CookieNotice.tsx

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookieNotice: React.FC = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // Check if the cookie consent has already been given
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setVisible(true);
        }
    }, []);

    const handleAcceptNecessary = () => {
        // Set necessary cookies in local storage
        localStorage.setItem('cookieConsent', 'necessary');
        setVisible(false);
    };

    const handleAcceptAll = () => {
        // Set all cookies in local storage
        localStorage.setItem('cookieConsent', 'all');
        setVisible(false);
    };

    if (!visible) {
        return null; // Don't render anything if not visible
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#f1f1f1',
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
            }}
        >
            <Typography variant="body2">
                This website uses cookies to enhance the user experience. By continuing to browse, you agree to our use of cookies.
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="outlined" color="primary" onClick={handleAcceptNecessary}>
                    Accept Necessary
                </Button>
                <Button variant="contained" color="primary" onClick={handleAcceptAll}>
                    Accept All
                </Button>
            </Box>
        </Box>
    );
};

export default CookieNotice;
