import { Word } from '../../../../types';

export const dailyActivities: Word[] = [
  {
    id: 'daily_1',
    french: 'se réveiller',
    english: 'to wake up',
    usage: 'Je me réveille à 7 heures tous les matins.' // I wake up at 7 AM every morning.
  },
  {
    id: 'daily_2',
    french: 'prendre une douche',
    english: 'to take a shower',
    usage: 'Je prends une douche après le petit-déjeuner.' // I take a shower after breakfast.
  },
  {
    id: 'daily_3',
    french: `s'habiller`,
    english: 'to get dressed',
    usage: `Je m'habille rapidement pour aller travailler.` // I get dressed quickly to go to work.
  },
  {
    id: 'daily_4',
    french: 'prendre le petit-déjeuner',
    english: 'to have breakfast',
    usage: 'Je prends le petit-déjeuner à 8 heures.' // I have breakfast at 8 AM.
  },
  {
    id: 'daily_5',
    french: 'aller au travail',
    english: 'to go to work',
    usage: 'Je vais au travail en bus.' // I go to work by bus.
  },
  {
    id: 'daily_6',
    french: 'déjeuner',
    english: 'to have lunch',
    usage: 'Je déjeune généralement avec mes collègues.' // I usually have lunch with my colleagues.
  },
  {
    id: 'daily_7',
    french: 'rentrer à la maison',
    english: 'to return home',
    usage: 'Je rentre à la maison vers 18 heures.' // I return home around 6 PM.
  },
  {
    id: 'daily_8',
    french: 'préparer le dîner',
    english: 'to prepare dinner',
    usage: 'Je prépare le dîner pour ma famille.' // I prepare dinner for my family.
  },
  {
    id: 'daily_9',
    french: 'regarder la télévision',
    english: 'to watch television',
    usage: 'Je regarde la télévision après le dîner.' // I watch television after dinner.
  },
  {
    id: 'daily_10',
    french: 'se coucher',
    english: 'to go to bed',
    usage: 'Je me couche vers 23 heures.' // I go to bed around 11 PM.
  }
];
