import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import Curriculum from './pages/Curriculum';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './pages/Contact';
import CookieNotice from './components/layout/CookieNotice';
import Course from './pages/Course';
import Quiz from './pages/Exercises';
import { Box, PaletteMode } from '@mui/material';
import { Login } from '@mui/icons-material';
import Signup from './pages/Signup';
import Notification from './components/Notification';
import { VocabularyLearning } from './pages/VocabularyLearning';

import { ThemeProvider} from '@mui/material/styles';
import { theme } from './styles/createTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

function App() {

  // const mode = theme.palette?.mode || 'light'; // Fallback to 'light' if undefined
  // const { mode, setMode } = useColorScheme();

  // const toggleMode = () => {
  //   setMode(mode === 'light' ? 'dark' : 'light');
  // };
  // <Button onClick={toggleMode}>
  //     Toggle {mode === 'light' ? 'Dark' : 'Light'} Mode
  //   </Button>

  return (
    <CssVarsProvider theme={theme}>
      <ThemeProvider theme={theme} >
      <CssBaseline />
      <Router>
        <Notification />
          <Box sx={{ paddingTop: '0px'}}>
              <Header />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact />} /> 
                  <Route path="/signup" element={<Signup />} /> 
                  <Route path="/login" element={<Login />} /> 
                  <Route path="/course" element={<Course />} /> 
                  <Route path="/course/:chapterId/:lessonId" element={<Course />} />
                  <Route path="/course/:chapterId/:lessonId/quiz" element={<Quiz />} />
                  <Route path="/curriculum" element={<Curriculum />} />
                  <Route path="/vocabulary" element={<VocabularyLearning />} />
              </Routes>
              <Footer />
              <CookieNotice />
            </Box>
        </Router>
        </ThemeProvider>
    </CssVarsProvider>
  );
}

export default App;
