import React, { useState } from 'react';
import { VocabularySelector } from '../components/VocabularySelector';
import { WordSelector } from '../components/WordSelector';
import { ReviewScheduler } from '../components/ReviewScheduler';
// Import other vocabulary sets as needed
import { Word } from '../types';
import { useUserProgress } from '../hooks/useUserProgress';
import { Box } from '@mui/material';
import { food } from '../data/french/vocabularies/french/food';
import { dailyActivities } from '../data/french/vocabularies/french/dailyActivities';
import { officeWork } from '../data/french/vocabularies/french/officeWork';
import { travel } from '../data/french/vocabularies/french/travel';
import FlashcardComponent from '../components/course/Flashcard';


// Define the vocabularies object
export const vocabularies = {
    officeWork,
    travel,
    food,
    dailyActivities
  };

export const VocabularyLearning: React.FC = () => {


  const [selectedVocabulary, setSelectedVocabulary] = useState<string | null>(null);
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const userId = 'user123'; // Replace with actual user ID
  const { addWordProgress } = useUserProgress(userId);

  const handleVocabularySelect = (vocabulary: string) => {
    setSelectedVocabulary(vocabulary);
    setSelectedWords([]);
    setCurrentWordIndex(0);
  };

  const handleWordSelect = (words: Word[]) => {
    setSelectedWords(words);
  };

  const handleFlashcardResult = (result: 'correct' | 'incorrect') => {
    addWordProgress(selectedWords[currentWordIndex].id, result === 'correct');
    // updateWordProgress(userId, currentWord, result === 'correct');
    if (currentWordIndex < selectedWords.length - 1) {
      setCurrentWordIndex(currentWordIndex + 1);
    } else {
      // Learning session complete
      setCurrentWordIndex(0);
      setSelectedWords([]);
    }
  };


  return (
    <Box sx={{ marginTop: 17, marginBottom: 7, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Vocabulary Learning</h1>
      {!selectedVocabulary && <VocabularySelector onSelect={handleVocabularySelect} />}
      {selectedVocabulary && selectedWords.length === 0 && (
        <WordSelector words={vocabularies[selectedVocabulary as keyof typeof vocabularies]} onSelect={handleWordSelect} />
      )}
      {selectedWords.length > 0 && (
        <FlashcardComponent word={selectedWords[currentWordIndex]} onResult={handleFlashcardResult} />
      )}
      <ReviewScheduler 
        userId={userId} 
        words={selectedVocabulary ? vocabularies[selectedVocabulary as keyof typeof vocabularies] : []} 
      />
    </Box>
  );
};
