import { Word } from '../../../../types';

export const travel: Word[] = [
  {
    id: 'travel_1',
    french: 'voyage',
    english: 'trip',
    usage: 'Je prépare mon voyage en France.' // I'm preparing my trip to France.
  },
  {
    id: 'travel_2',
    french: 'passeport',
    english: 'passport',
    usage: `N'oubliez pas votre passeport.` // Don't forget your passport.
  },
  {
    id: 'travel_3',
    french: 'valise',
    english: 'suitcase',
    usage: 'Ma valise est trop lourde.' // My suitcase is too heavy.
  },
  {
    id: 'travel_4',
    french: 'hôtel',
    english: 'hotel',
    usage: 'Nous restons dans un hôtel au centre-ville.' // We're staying in a hotel downtown.
  },
  {
    id: 'travel_5',
    french: 'réservation',
    english: 'reservation',
    usage: 'Avez-vous une réservation ?' // Do you have a reservation?
  },
  {
    id: 'travel_6',
    french: 'avion',
    english: 'airplane',
    usage: `L'avion décolle dans une heure.` // The plane takes off in an hour.
  },
  {
    id: 'travel_7',
    french: 'gare',
    english: 'train station',
    usage: 'Le train part de la gare centrale.' // The train leaves from the central station.
  },
  {
    id: 'travel_8',
    french: 'touriste',
    english: 'tourist',
    usage: 'Il y a beaucoup de touristes en été.' // There are many tourists in summer.
  },
  {
    id: 'travel_9',
    french: 'carte',
    english: 'map',
    usage: 'Pouvez-vous me montrer sur la carte ?' // Can you show me on the map?
  },
  {
    id: 'travel_10',
    french: 'plage',
    english: 'beach',
    usage: 'Nous allons à la plage demain.' // We're going to the beach tomorrow.
  }
];
