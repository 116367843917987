import React, { useEffect, useState } from 'react';
import { speakText } from '../utils/textToSpeech';
import { Word } from '../types';

interface Props {
  words: Word[];
  onSelect: (selectedWords: Word[]) => void;
  autoSelectCount?: number; // Optional prop to specify how many words to auto-select
}

export const WordSelector: React.FC<Props> = ({ words, onSelect, autoSelectCount = 10 }) => {
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);

  useEffect(() => {
    // Automatically select words when the component mounts
    if (words.length > 0) {
      const shuffledWords = [...words].sort(() => 0.5 - Math.random()); // Shuffle the words
      const autoSelectedWords = shuffledWords.slice(0, Math.min(autoSelectCount, shuffledWords.length)); // Select the first n words
      setSelectedWords(autoSelectedWords);
      onSelect(autoSelectedWords); // Notify parent component of the selected words
    }
  }, [words, autoSelectCount, onSelect]);

  const handleSpeak = (text: string) => {
    speakText(text, 'fr-FR', () => {}, () => {}, () => {})

  };

  return (
    <div>
      <h2>Selected Words</h2>
      {selectedWords.map((word) => (
        <div key={word.id}>
          <span>{word.french}</span>
          <button onClick={() => handleSpeak(word.french)}>🔊</button>
        </div>
      ))}
      <button onClick={() => onSelect(selectedWords)}>Start Learning</button>
    </div>
  );
};
